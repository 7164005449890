<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleDialog" max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('role-create')"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("add_new_role") }}
        </v-btn>
      </template>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("role") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('name')"
                        v-model="role.name"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col cols="12"> {{ $t("permissions") }} </v-col>

                  <v-container v-for="(group, key) in permissions" :key="key">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-checkbox
                              dense
                              outlined
                              hide-details
                              multiple
                              v-model="groups_name"
                              :label="key"
                              :value="key"
                              @change="updateRolePermissions(key, role)"
                            ></v-checkbox>
                          </v-col>

                          <v-col
                            cols="3"
                            v-for="permission in group"
                            :key="permission.id"
                          >
                            <v-checkbox
                              dense
                              outlined
                              hide-details
                              v-model="role.permission_ids"
                              :label="permission.name"
                              :value="permission.id"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(role)"
              :disabled="!is_valid"
              :loading="is_loading_save"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      hubs: "hubs/listHubs",
      roles: "roles/listRoles",
      permissions: "permissions/listPermissions",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.roles.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("roles/IS_VISIBLE_DIALOG", value);
      },
    },
    role: {
      get() {
        return this.$store.state.roles.editedRole;
      },
      set(value) {
        this.$store.commit("roles/EDITED_ROLE", value);
      },
    },
  },

  data() {
    return {
      groups_name: [],
      is_valid: true,
      is_loading_save: false,
    };
  },
  methods: {
    closeForm() {
      this.$store.dispatch("roles/closeForm");
      this.$refs.form.reset();
      this.is_loading_save = false;
    },

    async saveForm(role) {
      if (this.$refs.form.validate()) {
        try {
          this.is_loading_save = true;
          if (role.id) {
            await this.$store.dispatch("roles/update", role);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("roles/add", role);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.is_loading_save = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_loading_save = false;
        }
        this.is_loading_save = false;
      }
    },

    updateRolePermissions(current_group, role) {
      const permission_ids = this.permissions[current_group].map((item) => {
        return item.id;
      });

      if (this.groups_name.includes(current_group)) {
        permission_ids.forEach(function (id) {
          if (role.permission_ids.indexOf(id) == -1)
            role.permission_ids.push(id);
        });
      } else {
        permission_ids.forEach(function (id) {
          role.permission_ids = role.permission_ids.filter(function (item) {
            return item !== id;
          });
        });
      }
    },
  },
};
</script>
